import type {
  CTAStyling,
  Mood,
  Tone,
} from '@backmarket/http-api/src/api-specs-content/shared/cta-styling'
import { tw } from '@backmarket/utils/string/tw'

type Selectors = { button?: string; text?: string; wrapper?: string }

const combinations: Record<Tone, Record<Mood, Selectors>> = {
  mindaro: {
    bush: { text: tw`mood-bush`, wrapper: tw`mood-main` },
    coffee: { text: tw`mood-coffee`, wrapper: tw`mood-main` },
    tangaroa: { text: tw`mood-tangaroa`, wrapper: tw`mood-main` },
    violet: { text: tw`mood-violet`, wrapper: tw`mood-main` },
  },
  'dark-tones': {
    bush: { button: tw`mood-bush`, text: tw`mood-main` },
    coffee: { button: tw`mood-coffee`, text: tw`mood-main` },
    tangaroa: { button: tw`mood-tangaroa`, text: tw`mood-main` },
    violet: { button: tw`mood-violet`, text: tw`mood-main` },
  },
  'mid-tones': {
    bush: { button: tw`mood-emerald`, text: tw`mood-bush` },
    coffee: { button: tw`mood-blush`, text: tw`mood-coffee` },
    tangaroa: { button: tw`mood-cornflower`, text: tw`mood-tangaroa` },
    violet: { button: tw`mood-purple`, text: tw`mood-violet` },
  },
}

export function getCTAColors(ctaStyling: CTAStyling) {
  if (ctaStyling === 'primary') {
    return { button: tw`mood-main`, text: tw`mood-inverse` }
  }
  if (ctaStyling === 'secondary') {
    return { button: tw`mood-inverse`, text: tw`mood-main` }
  }

  const styleSplit = ctaStyling.split('_')
  const tone = styleSplit[0] as Tone
  const mood = styleSplit[1] as Mood

  return combinations[tone][mood]
}
