<template>
  <div v-if="hasLinks">
    <BlockTitle :center="props.align === 'Center'" :subtitle :title />

    <div :class="containerClasses">
      <div
        v-for="(relatedLink, index) in parsedRelatedLinks"
        :key="`${relatedLink.label}_${id}`"
        :class="getCTAColors(relatedLink.mood).wrapper"
      >
        <RevButton
          class="shadow-short rounded-round !py-8 !pl-16"
          :class="getButtonClasses(relatedLink.mood, !!relatedLink.icon)"
          :rel="relatedLink.link?.rel"
          :size="relatedLinkSize"
          :target="relatedLink.link?.target"
          :to="relatedLink.link?.href"
          variant="primary"
          @click="relatedLinkClick($event, index, relatedLink)"
        >
          <span class="flex items-center">
            <span :class="getTextClasses(relatedLink.mood)">
              {{ relatedLink.label }}
            </span>

            <DynamicIcon
              v-if="relatedLink.icon"
              class="ml-8"
              :class="[
                getTextClasses(relatedLink.mood),
                relatedLinkSize === 'small' ? '!size-20' : '!size-24',
              ]"
              v-bind="relatedLink.icon"
            />
          </span>
        </RevButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRequestURL, useRouter } from '#imports'
import { computed, onMounted } from 'vue'

import { transformHref } from '@backmarket/front-office/scopes/cms/helpers/link/transformHref'
import type { RelatedLink } from '@backmarket/http-api/src/api-specs-content/models/related-link'
import type { RelatedLinkProps } from '@backmarket/http-api/src/api-specs-content/models/related-links-content'
import type { CTAStyling } from '@backmarket/http-api/src/api-specs-content/shared/cta-styling'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { tw } from '@backmarket/utils/string/tw'
import { urlHashToObject } from '@backmarket/utils/url/urlHashToObject'
import { RevButton } from '@ds/components/Button'

import BlockTitle from '@backmarket/nuxt-layer-cms/BlockTitle.vue'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'

import DynamicIcon from '../../shared-components/DynamicIcon/DynamicIcon.vue'
import { getCTAColors } from '../../utils/CTAColorCombinations'

export type RelatedLinksProps = ContentBlockProps & RelatedLinkProps

const props = withDefaults(defineProps<RelatedLinksProps>(), {
  title: '',
  subtitle: '',
  size: 'Small',
  display: 'Wrap',
  align: 'Left',
})

const { trackClick } = useTracking()
const currentUrl = useRequestURL()
const router = useRouter()

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const hasLinks = computed(() => props.links?.length > 0)

const parsedRelatedLinks = computed<RelatedLink[]>(() => {
  const relatedLinks: RelatedLink[] = []

  for (let i = 0; i < props.links.length; i += 1) {
    const relatedLink = props.links[i]
    if (!relatedLink.label || !relatedLink.link) continue

    relatedLinks.push({
      label: relatedLink.label,
      link: transformHref({ href: relatedLink.link.href, currentUrl }),
      icon: 'icon' in relatedLink ? relatedLink.icon : undefined,
      mood: 'mood' in relatedLink ? relatedLink.mood : 'primary',
    })
  }

  return relatedLinks
})

const relatedLinkSize = computed(() => {
  return props.size === 'Small' ? 'small' : 'medium'
})

const containerClasses = computed(() => {
  return [
    tw`flex gap-8 pb-4 -mb-4 pl-24 -ml-24`,
    props.align === 'Center' && tw`justify-center-safe`,
    props.display === 'Wrap' && tw`flex-wrap`,
    props.display === 'Inline-Scroll' &&
      tw`-mr-24 flex-nowrap overflow-x-auto scrollbar-none pr-24 md:mr-0 md:flex-wrap md:pr-0`,
  ]
})

function relatedLinkClick(
  event: Event,
  index: number,
  relatedLink: RelatedLink,
) {
  trackClick({
    ...props.tracking,
    position: index + 1,
    name: relatedLink.label,
  })

  const { href } = relatedLink.link
  const { target }: { target?: string } = urlHashToObject(href)
  const { pathname } = currentUrl
  const samePage = pathname === href.split('#scroll=false&target=')[0]

  if (target?.length && samePage) {
    if (event) event.preventDefault()

    const targetId = `scroll=false&target=${target[0]}`
    router.replace({ hash: `#${targetId}` })

    const blockElement = document.getElementById(targetId)
    if (blockElement) {
      blockElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }
}

function getButtonClasses(ctaStyling: CTAStyling, hasIcon: boolean) {
  const buttonClasses = getCTAColors(ctaStyling).button
  const height = relatedLinkSize.value === 'small' ? tw`h-36` : tw`h-40`
  const padding = hasIcon ? tw`pr-12` : tw`pr-16`

  if (ctaStyling === 'primary' || ctaStyling === 'secondary') {
    return [buttonClasses, height, padding]
  }

  return [
    tw`!bg-local-cms-action-brand hover:!bg-local-cms-action-brand-hover`,
    buttonClasses,
    height,
    padding,
  ]
}

function getTextClasses(ctaStyling: CTAStyling) {
  const textClasses = getCTAColors(ctaStyling).text

  if (ctaStyling === 'primary' || ctaStyling === 'secondary') {
    return textClasses
  }

  return [tw`text-local-cms-brand`, textClasses]
}

onMounted(() => {
  const { target }: { target?: string } = urlHashToObject(currentUrl.hash)

  if (target?.length) {
    const blockElement = document.getElementById(
      `scroll=false&target=${target[0]}`,
    )

    if (blockElement) {
      blockElement.scrollIntoView({ behavior: 'instant', block: 'start' })
    }
  }
})
</script>
