import { tw } from '@backmarket/utils/string/tw'

import type { SectionBannerProps } from '~/blocks/SectionBanner/SectionBanner.types'

import type { BackgroundProps } from '../../shared-components/Background/Background.types'
import { hasBackground } from '../../shared-components/Background/Background.utils'

export function getPaddingClass(options: Pick<BackgroundProps, 'background'>) {
  const horizontalPadding = tw`px-24 md:px-72 lg:px-0`

  if (!hasBackground(options)) return tw`${horizontalPadding} py-0`

  return tw`${horizontalPadding} py-48 md:py-72`
}

export function getBlockPadding(
  additionalSpacing?: SectionBannerProps['additionalSpacing'],
) {
  if (!additionalSpacing) return ''

  switch (additionalSpacing) {
    case 'Top':
      return tw`pt-48 md:pt-72`
    case 'Bottom':
      return tw`pb-48 md:pb-72`
    case 'Top-Bottom':
      return tw`py-48 md:py-72`
    default:
      return ''
  }
}
